import { template } from "@ember/template-compiler";
import { isAfter } from 'tio-common/utils/date/is-after';
import { not } from 'ember-truth-helpers';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import AuthRegisterSso from 'tio-employee/components/auth/register/sso';
import Component from '@glimmer/component';
import EmailLookupWrapper from 'tio-employee/components/auth/email-lookup-wrapper';
import EmployeeRegisterForm from 'tio-employee/components/auth/register/employee-register-form';
import LoginHere from 'tio-employee/components/auth/login-here';
import NotLaunched from 'tio-employee/components/auth/register/not-launched';
import PayrollIdForm from 'tio-employee/components/auth/register/payroll-id-form';
import RouteTemplate from 'ember-route-template';
import type { IntlService } from 'ember-intl';
import type PreRegisterService from '../../services/pre-register.ts';
import type RegisterController from 'tio-employee/controllers/register';
interface RegisterComponentSignature {
    Args: {
        controller: RegisterController;
    };
}
let RegisterComponent = class RegisterComponent extends Component<RegisterComponentSignature> {
    @service
    preRegister: PreRegisterService;
    @service
    intl: IntlService;
    get settings() {
        return this.preRegister.settings;
    }
    get company() {
        return this.preRegister.sparseCompany;
    }
    get companyName() {
        return this.company?.name ?? '';
    }
    get daysUntilLaunch() {
        if (!this.company?.launchDate || this.isLaunched) return 0;
        const targetDate1 = new Date(this.company?.launchDate);
        const currentDate1 = new Date();
        const timeDifference1 = targetDate1.getTime() - currentDate1.getTime();
        const daysDifference1 = Math.ceil(timeDifference1 / (1000 * 60 * 60 * 24));
        return daysDifference1;
    }
    get isLaunched() {
        return this.company?.launchDate ? isAfter(new Date(), this.company.launchDate) : true;
    }
    get emailFromQueryParams() {
        const urlParams1 = new URLSearchParams(window.location.search);
        return decodeURIComponent(urlParams1.get('email') || '');
    }
    get isSso() {
        return !!(this.settings?.ssoAuthUrl && this.settings?.idProvider && this.settings?.idProvider !== 'NONE');
    }
    get isPayrollIdAndLastNameAllowed() {
        return !!this.settings?.register?.isPayrollIdAndLastNameAllowed;
    }
    get isEmailRegisterAllowed() {
        return !!this.settings?.register?.isEmailRegisterAllowed;
    }
    static{
        template(`
    <div class="flex flex-col h-screen container mx-auto w-full overflow-auto">
      <EmailLookupWrapper @route="register">
        {{#if (not this.isLaunched)}}
          <NotLaunched @companyName={{this.companyName}} @days={{this.daysUntilLaunch}} />
        {{else if this.isSso}}
          <AuthRegisterSso @settings={{this.settings}} />
        {{else if this.isEmailRegisterAllowed}}
          <EmployeeRegisterForm @settings={{this.settings}} @email={{this.emailFromQueryParams}} />
        {{else if this.isPayrollIdAndLastNameAllowed}}
          <PayrollIdForm @settings={{this.settings}} @email={{this.emailFromQueryParams}} />
        {{else}}
          <div class="p-5 text-center">
            <h1 class="text-lg mt-2 mb-6 mx-auto">
              {{t "error_occurred"}}
            </h1>
            <LoginHere />
          </div>
        {{/if}}
      </EmailLookupWrapper>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(RegisterComponent);
