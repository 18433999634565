import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { all, dropTask } from 'ember-concurrency';
import { Button } from 'tio-ui/components/buttons';
import { Divider } from 'tio-ui/components/utilities';
import { Input } from 'tio-ui/components/forms';
import { Modal } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { getAllValuesForTasField, getSingleValueForTasField, setSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import AcceptConditions from 'tio-employee/components/tuition-assistance/forms/accept-conditions';
import ActionableList from 'tio-ui/components/actionable-list';
import AddEducationDrawer from 'tio-employee/components/tas/add-education-drawer';
import AddScholarshipsDrawer from 'tio-employee/components/tas/add-scholarships-drawer';
import AppPage from 'tio-ui/components/app-page';
import CheckCircle from 'ember-static-heroicons/components/outline-24/check-circle';
import CheckCircleSolid from 'ember-static-heroicons/components/solid-24/check-circle';
import ClipboardDocumentList from 'ember-static-heroicons/components/outline-24/clipboard-document-list';
import Component from '@glimmer/component';
import divide from 'ember-math-helpers/helpers/div';
import DrawerTarget from 'tio-ui/components/drawer-target';
import InlineEdit from 'tio-ui/components/inline-edit';
import LimitsSummary from 'tio-common/components/tuition-assistance/limits-summary';
import MissingRequirements from 'tio-employee/components/tas/missing-requirements';
import PencilSquare from 'ember-static-heroicons/components/outline-24/pencil-square';
import Plus from 'ember-static-heroicons/components/solid-24/plus';
import ReimbursementCalculationIfApplicable from 'tio-common/components/tuition-assistance/reimbursement-calculation-if-applicable';
import RouteTemplate from 'ember-route-template';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import TasCardGeneric from 'tio-ui/components/card-generic';
import TasPageHeader from 'tio-employee/components/tas/page-header';
import XCircle from 'ember-static-heroicons/components/outline-24/x-circle';
import type { TOC } from '@ember/component/template-only';
import type ConditionModel from 'tio-common/models/condition';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TasCourseModel from 'tio-common/models/tas-course';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplateModel from 'tio-common/models/tas-program-template';
import UploadAttachments from 'tio-common/components/tuition-assistance/forms/upload-attachments';
interface HeaderSignature {
    Args: {
        title: string;
        required?: boolean;
        subtitle?: string;
        subHeader?: string;
    };
    Element: HTMLDivElement;
}
const Header: TOC<HeaderSignature> = template(`
  <div class="my-4" ...attributes>
    <h1 class="text-grey-600 text-2xl">{{@title}}</h1>
    <p>
      {{#if @required}}
        <span class="text-error-400 text-xl">*</span>
      {{/if}}
      {{@subtitle}}</p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const Header2: TOC<HeaderSignature> = template(`
  <div ...attributes>
    <h1 class="text-grey-600 text-2xl">{{@title}}
      {{#if @required}}
        <span class="text-error-400 text-2xl">*</span>
      {{/if}}
      {{#if @subHeader}}
        <span class="text-grey-400 text-lg">{{@subHeader}}</span>
      {{/if}}
    </h1>
    <p>{{@subtitle}}</p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export interface TypeTwoNewSignature {
    Args: {
        model: {
            application: TasApplicationModel;
            instance: TASProgramInstanceModel;
            courses: TasCourseModel[];
        };
    };
}
let AuthenticatedTasApplicationsTypeTwoNew = class AuthenticatedTasApplicationsTypeTwoNew extends Component<TypeTwoNewSignature> {
    programTemplate: TasProgramTemplateModel;
    constructor(owner1: unknown, args1: TypeTwoNewSignature['Args']){
        super(owner1, args1);
        const { application: application1 } = this.args.model;
        this.programTemplate = application1.tasProgramInstance.tasProgramTemplate;
        this.course = null;
    }
    @tracked
    isEducationOpen = false;
    @tracked
    isSchorlarshipOpen = false;
    @tracked
    isDeleteApplicationOpen = false;
    @tracked
    isOpen = false;
    @tracked
    acceptedConditions: ConditionModel[] = [];
    @tracked
    course: TasCourseModel | null;
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @service
    router: RouterService;
    get applicationName() {
        return getSingleValueForTasField('APPLICATION_NAME', this.args.model.application.fields);
    }
    get conditionsForSubmit() {
        return this.args.model.instance.tasProgramTemplate.typeTwoApplicationConditions || [];
    }
    get hasAgreedAllConditions() {
        if (!this.conditionsForSubmit.length) {
            return true;
        }
        return this.conditionsForSubmit.length === this.acceptedConditions.length;
    }
    get applicationFields(): TasApplicationModel['fields'] {
        return this.args.model.application.fields;
    }
    saveAgreementsForConditions = dropTask(async ()=>{
        const user1 = this.sessionContext.user;
        const agreements1 = this.acceptedConditions.map((condition1)=>{
            return this.store.createRecord('agreement', {
                user: user1,
                condition: condition1
            });
        });
        const promises1 = agreements1.map((agreement1)=>{
            return agreement1.save();
        });
        const saved1 = await all(promises1);
        return saved1;
    });
    get requireAttachmentUploads() {
        if (this.programTemplate.isCertificateProgram) {
            return (this.programTemplate.requireAttachmentsForCourseCompletion || this.programTemplate.isEvidenceRequired);
        }
        return this.programTemplate.isEvidenceRequired;
    }
    get completionDocumentsList() {
        return (getAllValuesForTasField('COMPLETION_DOCUMENTS_ATTACHMENT_FIELD', this.programTemplate.fields).join(', ') || 'None Required');
    }
    get coursesAdded() {
        console.log('courses added', !!this.args.model.application?.tasCourses?.length || false);
        return !!this.args.model.application?.tasCourses?.length || false;
    }
    get scholarshipsAdded() {
        return !!this.args.model.application?.scholarships?.length || false;
    }
    @action
    didUpdateAcceptedConditions(conditions1: ConditionModel[] = []) {
        this.acceptedConditions = conditions1;
    }
    @action
    saveStartDate(value1: string) {
        console.log('Save Start Date');
        setSingleValueForTasField('COURSES_BEGIN_DATE', value1, this.applicationFields);
    }
    @action
    updateAppName(value1: string) {
        setSingleValueForTasField('APPLICATION_NAME', value1, this.applicationFields);
    }
    @action
    openEducationDrawer() {
        this.course = this.store.createRecord('tas-course', {
            tasApplication: this.args.model.application,
            fields: this.programTemplate.courseFieldsHash,
            customFields: this.programTemplate.courseCustomFields
        });
        this.isEducationOpen = true;
    }
    @action
    closeEducationDrawer() {
        this.course = null;
        this.isEducationOpen = false;
    }
    @action
    toggleScholarshipDrawer() {
        this.isSchorlarshipOpen = !this.isSchorlarshipOpen;
    }
    get canSubmitApplication() {
        const { application: application1 } = this.args.model;
        return (this.hasAgreedAllConditions && application1.hasRequiredEvidence && application1.state === 'TAS.ApplicationState.ATTEND');
    }
    @action
    submitApplication() {
        if (this.canSubmitApplication) {
            this.args.model.application.save();
        }
        this.router.transitionTo('authenticated.tas.applications.show', this.args.model.application.id, {
            queryParams: {
                applicationSubmitted: true
            }
        });
    }
    @action
    onSave() {
        this.args.model.application.save();
    }
    @action
    onFinishLater() {
        if (this.args.model.application.dirtyType === 'updated') {
            this.args.model.application.save();
            this.router.transitionTo('authenticated.tas.dashboard');
        } else {
            this.router.transitionTo('authenticated.tas.dashboard');
        }
    }
    @action
    onTrash() {
        this.isDeleteApplicationOpen = true;
    }
    @action
    toggleDeleteApplication() {
        this.isDeleteApplicationOpen = !this.isDeleteApplicationOpen;
    }
    @action
    async deleteApplication() {
        const adapter1 = this.store.adapterFor('tas-application');
        try {
            await adapter1.abandonCourse(this.args.model.application);
        } catch (e1) {
            console.error(e1);
        }
        this.router.transitionTo('authenticated.tas.dashboard');
    }
    inputClasses = {
        base: 'w-full mb-6'
    };
    static{
        template(`
    <AppPage>
      <:title>
        <div class="mr-6">
          <TasPageHeader>
            <:title as |title|>
              <title.BackLink @label="Back" @route="authenticated.tas.dashboard">
                {{t "sidebar.tuition_assistance"}}
              </title.BackLink>
            </:title>
            <:actions as |actions|>
              <actions.Button {{on "click" this.onSave}}>{{t "save"}}</actions.Button>
              <actions.Button {{on "click" this.onFinishLater}}>
                {{t "application.type_two.new.finish_later"}}
              </actions.Button>
              <actions.IconButton {{on "click" this.onTrash}} @type="trash" />
            </:actions>
          </TasPageHeader>
        </div>
      </:title>
      <:content>
        <DrawerTarget />
        <div class="flex flex-wrap lg:flex-nowrap gap-x-4 w-full">
          <div class="flex flex-col w-full">
            <Header @title="Application" @required={{true}} @subtitle="Required Items" />
            <section class="lg:w-3/4">
              <div class="flex items-center w-full py-4">
                <div class="w-1/2">
                  <h2 class="text-grey-600 font-semibold">{{t
                      "application.type_two.new.start_date"
                    }}</h2>
                </div>
                <div class="w-1/2">
                  <InlineEdit
                    @value="10/31/2024"
                    @onSave={{this.saveStartDate}}
                    @inputType="date"
                  />
                </div>
              </div>
              <Divider class="my-2" />
              <div class="flex items-center w-full py-4">
                <div class="w-1/2">
                  <h2 class="text-grey-600 font-semibold">{{t "application.type_two.new.name"}}
                    <span class="text-error-400 text-xl">*</span>
                  </h2>
                </div>
                <div class="w-1/2">
                  <label for="application-name" class="sr-only">{{t
                      "application.type_two.new.name"
                    }}</label>
                  <Input
                    size="lg"
                    id="application-name"
                    @value={{getSingleValueForTasField
                      "APPLICATION_NAME"
                      @model.application.fields
                    }}
                    class="!bg-transparent"
                    @onChange={{this.updateAppName}}
                  />
                </div>
              </div>
              <Divider class="my-2" />
            </section>
            {{! Add Education}}
            <section>
              <div
                class="mt-20 flex gap-4 justify-between
                  {{if this.coursesAdded 'flex-row' 'flex-col'}}"
              >
                <Header2
                  @title="Education"
                  @subtitle={{if this.coursesAdded "" "Add Education for reimbursement"}}
                  @required={{true}}
                  class={{if this.coursesAdded "self-end"}}
                />
                <div>
                  <button
                    type="button"
                    class="flex py-3 px-6 me-2 mb-2 font-medium text-lg text-ocean-600 focus:outline-none bg-white rounded-lg border border-ocean-600 hover:bg-ocean-50 hover:text-ocean-700"
                    {{on "click" this.openEducationDrawer}}
                  >
                    <Plus class="text-ocean-600 h-6 w-6 mr-2" />
                    {{t "application.type_two.new.education"}}
                  </button>
                </div>
              </div>
              <Divider class="my-2" />
              <ActionableList @striped={{false}} as |l|>
                {{#each @model.courses as |course|}}
                  <l.Row>
                    <l.Term>{{course.courseName}}</l.Term>
                    <l.Details>
                      <l.Item class="justify-self-center">
                        {{safeFormatNumber
                          (divide course.courseTotal 100)
                          style="currency"
                          currency="USD"
                        }}
                      </l.Item>
                      <l.Item
                        class="justify-self-center md:justify-self-start font-semibold"
                      >{{course.courseGrade}}</l.Item>
                    </l.Details>
                    <l.Action>
                      <button type="button"><XCircle class="h-6 w-6" /></button>
                      <button type="button"><PencilSquare class="h-6 w-6" /></button>
                    </l.Action>
                  </l.Row>
                {{/each}}
              </ActionableList>
            </section>
            {{! Add Scholarships }}
            <section>
              <div
                class="mt-20 flex gap-4 justify-between
                  {{if this.scholarshipsAdded 'flex-row' 'flex-col'}}"
              >
                <Header2
                  @title="Scholarships"
                  @subHeader="(Optional)"
                  @subtitle={{if
                    this.scholarshipsAdded
                    ""
                    "Please enter any scholarships you have received during this Education."
                  }}
                  class={{if this.scholarshipsAdded "self-end"}}
                />
                <div>
                  <button
                    type="button"
                    class="flex py-3 px-6 me-2 mb-2 font-medium text-lg text-ocean-600 focus:outline-none bg-white rounded-lg border border-ocean-600 hover:bg-ocean-50 hover:text-ocean-700"
                    {{on "click" this.toggleScholarshipDrawer}}
                  >
                    <Plus class="text-ocean-600 h-6 w-6 mr-2" />
                    {{t "application.type_two.new.scholarship"}}
                  </button>
                </div>
              </div>
            </section>

            <Divider class="my-2" />
            {{! Upload Attachments}}
            <section>
              <div class="mt-20">
                <Header2
                  @title="Attachments"
                  @required={{this.requireAttachmentUploads}}
                  @subtitle="Upload documents as required by your program administrator."
                />
              </div>
              <div class="flex items-center max-w-lg py-4">
                <UploadAttachments
                  @programTemplate={{this.programTemplate}}
                  @classification="COMPLETION_DOCUMENTS"
                  @attachable={{@model.application}}
                  @attachableType="tasApplication"
                  @useDropzone={{true}}
                  {{!-- @disabled={{this.disableAttachmentUpload}} --}}
                />
              </div>
            </section>
            <AddEducationDrawer
              @isOpen={{this.isEducationOpen}}
              @onClose={{this.closeEducationDrawer}}
              @application={{@model.application}}
              @course={{this.course}}
            />
            <AddScholarshipsDrawer
              @isOpen={{this.isSchorlarshipOpen}}
              @onClose={{this.toggleScholarshipDrawer}}
              @application={{@model.application}}
            />
          </div>

          {{! Benefits Summary Window}}
          <section class="sm:mx-4 lg:mx-8 md:flex flex-col">
            <div class="flex items-center mt-4">
              <TasCardGeneric>
                <:header>
                  <h2>{{t "benefits_summary.title"}}</h2>
                </:header>
                <:body>
                  <LimitsSummary
                    @application={{@model.application}}
                    @employee={{@model.application.tasProgramInstance.employee}}
                    @labelClass="col-span-5"
                    @valueClass="col-span-2 text-violet-800"
                    class="my-4"
                    as |Summary|
                  >
                    <div class="w-full grid grid-cols-7 gap-2 mt-3">
                      {{#unless Summary.isUnlimitedMaximumAnnualBenefit}}
                        <Summary.MaxAnnualBenefitLineItem />
                      {{/unless}}

                      {{#if Summary.hasLimitsErrorForEmployee}}
                        <Summary.RemainingBenefitLineItem />
                      {{/if}}
                      <Summary.ApplicationAmountRequestedWithoutScholarshipsLineItem
                        @valueClass="text-tio-gray-800 col-span-2"
                      />
                      {{#if this.programTemplate.scholarshipsAreVisible}}
                        <Summary.ScholarshipAmountLineItem
                          @valueClass="text-tio-gray-800 !font-normal col-span-2"
                        />
                      {{/if}}
                      <Summary.LimitsAlertForEmployeeIfApplicable class="text-sm col-span-full" />
                      <ReimbursementCalculationIfApplicable @application={{@model.application}} />
                      <Divider class="my-2 col-span-7" />
                      <Summary.ApplicationAmountRequestedLineItem />
                    </div>
                    {{! Required Items Section }}
                    <section class="my-5">
                      <header
                        class="border-y bg-tio-gray-25 flex items-center gap-2 py-2 px-5 text-sm font-medium tracking-wide -mx-6"
                      >
                        <ClipboardDocumentList class="h-8 w-8" />
                        <span>{{t "benefits_summary.required_items"}}</span>
                      </header>

                      <div>
                        <div class="flex flex-row justify-between items-center">
                          <p>{{t "benefits_summary.attachments"}}
                            <span class="text-error-400 ml-1">
                              {{#if this.requireAttachmentUploads}}*{{/if}}
                            </span>
                          </p>
                          <div>
                            <UploadAttachments
                              @programTemplate={{this.programTemplate}}
                              @classification="COMPLETION_DOCUMENTS"
                              @attachable={{@model.application}}
                              @attachableType="tasApplication"
                              @useDropzone={{false}}
                              @iconOnlyUpload={{true}}
                              @hideAttachments={{true}}
                              @hideInstructions={{true}}
                              {{!-- @disabled={{this.disableAttachmentUpload}} --}}
                            />
                          </div>
                        </div>
                        <div class="flex items-center gap-3 -mt-1">
                          {{#if @model.application.isMissingRequiredEvidence}}
                            <CheckCircle class="h-6 w-6 text-tio-gray-200" />
                          {{else}}
                            <CheckCircleSolid class="h-6 w-6 text-violet-500" />
                          {{/if}}
                          <p>{{this.completionDocumentsList}}</p>
                        </div>
                      </div>

                      {{#if this.conditionsForSubmit.length}}
                        <AcceptConditions
                          @conditions={{this.conditionsForSubmit}}
                          @onChange={{this.didUpdateAcceptedConditions}}
                        />
                      {{/if}}
                    </section>
                    <div class="py-4">
                      {{#if this.canSubmitApplication}}
                        <Button
                          class="flex justify-center w-full py-3 px-6 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg hover:bg-ocean-400"
                          {{on "click" this.submitApplication}}
                        >
                          {{t "benefits_summary.submit_application"}}
                        </Button>
                      {{else}}
                        <MissingRequirements />
                      {{/if}}
                      <p class="text-sm font-light text-center">
                        {{t "benefits_summary.helper_text"}}
                      </p>
                    </div>
                  </LimitsSummary>
                </:body>
              </TasCardGeneric>
            </div>
          </section>
        </div>
      </:content>
    </AppPage>

    <Modal
      @isOpen={{this.isDeleteApplicationOpen}}
      @onClose={{this.toggleDeleteApplication}}
      as |m|
    >
      <m.Header>{{t "application.type_two.new.delete_application"}}</m.Header>
      <m.Body>
        <p>{{t "application.type_two.new.confirm_delete"}}</p>
      </m.Body>
      <m.Footer>
        <Button @appearance="minimal" class="mr-4" {{on "click" this.toggleDeleteApplication}}>
          {{t "cancel"}}
        </Button>
        <Button @intent="danger" {{on "click" this.deleteApplication}}>
          {{t "delete"}}
        </Button>
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AuthenticatedTasApplicationsTypeTwoNew);
