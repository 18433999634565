import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import ApplicationHistory from 'tio-common/components/tas/application-history';
import PaymentDetailsCard from 'tio-common/components/tas/payment-details-card';
import RouteTemplate from 'ember-route-template';
import type { TasAppWithHistory } from 'tio-common/types/tuition-assistance';
import Component from '@glimmer/component';
interface TasApplicationHistorySignature {
    Args: {
        model: TasAppWithHistory;
    };
}
let TasApplicationHistory = class TasApplicationHistory extends Component<TasApplicationHistorySignature> {
    get payments() {
        return this.args.model.history.filter((item1)=>item1.logType === 'assistance');
    }
    static{
        template(`
    <div class="p-6 flex lg:flex-row sm:flex-col justify-between w-full">
      <div>
        <h2 class="text-lg mb-4">{{t "application.history.title"}}</h2>
        <ApplicationHistory @history={{@model.history}} />
      </div>
      <PaymentDetailsCard
        @isPaid={{@model.isPaid}}
        @requestedTotal={{@model.requestedTotal}}
        @payments={{this.payments}}
      />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(TasApplicationHistory);
