import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type StoreService from 'tio-common/services/store';
import type { TasAppWithHistory } from 'tio-common/types/tuition-assistance';

export default class AuthenticatedTasApplicationsHistoryRoute extends Route {
  @service declare store: StoreService;

  includes = ['tas-courses', 'tas-program-instance.tas-program-template'];

  async model({ tas_application_id }: { tas_application_id: string }): Promise<TasAppWithHistory> {
    // TODO: make this 1 call
    const application = await this.store.findRecord('tas-application', tas_application_id, {
      include: this.includes.join(','),
      reload: true,
    });
    const history = await this.store.adapterFor('tas-application').getHistory(application);
    application.history = history.data;
    return application;
  }
}
