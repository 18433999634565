import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { and, not } from 'ember-truth-helpers';
import { concat } from '@ember/helper';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import DefinitionList from 'tio-common/components/ui/definition-list';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import TuitionAssistanceDashboardProgramHeader from './program-header';
import TuitionAssistanceProgramCard from 'tio-common/components/tuition-assistance/program-card';
import type IntlService from 'ember-intl/services/intl';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import UiPill from 'tio-common/components/ui/pill';
export interface TuitionAssistanceMyProgramsSignature {
    Args: {
        programInstances?: TasProgramInstanceModel[];
        sectionHeader?: string;
        programStatus?: string;
    };
    Element: HTMLDivElement;
}
export type ProgramOverviewData = {
    title: string;
    instanceId: string;
    isTrial: boolean;
    statusText: string;
    date: string;
    buttonRoute: string;
    buttonRouteModel: string;
    buttonText: string;
    institution: {
        [key: string]: string;
    };
    beginDate: string;
    beginDateVisible: boolean;
    endDate: string;
    endDateVisible: boolean;
    result: CourseApplicationResults;
    state: string;
    isTrackingOnlyProgram: boolean;
    programMajor: {
        [key: string]: string;
    };
};
export type CourseApplicationResults = {
    pending: number;
    in_progress: number;
    completed: number;
};
export default class TuitionAssistanceMyPrograms extends Component<TuitionAssistanceMyProgramsSignature> {
    @service
    intl: IntlService;
    get programInstances() {
        return this.args.programInstances || [];
    }
    get instances() {
        return this.programInstances.map((instance1)=>{
            const institutionLabel1 = instance1.tasProgramTemplate.fields['SCHOOL_INSTITUTION_NAME'].label;
            const majorLabel1 = instance1.tasProgramTemplate.fields?.['PROGRAM_MAJOR']?.label;
            const courseApplicationResults1 = this.courseApplicationData(instance1);
            const institutionName1 = instance1.institutionName || this.intl.t('tuition_assistance.not_provided');
            // this is a temporary solution to differentiate between legacy and typed programs
            const typeClassificationSet1 = !!instance1.tasProgramTemplate.typeClassification;
            const modelId1 = typeClassificationSet1 ? instance1.tasApplications[0]?.id : instance1.id;
            const buttonRoute1 = typeClassificationSet1 ? 'authenticated.tas.applications.show' : 'authenticated.tuition-assistance.programs.instance';
            return {
                title: instance1.programName,
                instanceId: instance1.id,
                isTrial: instance1.isTrial,
                statusText: instance1.latestStateChangeLabel,
                date: instance1.latestStateChangeDate,
                buttonRoute: buttonRoute1,
                buttonRouteModel: modelId1,
                buttonText: this.intl.t('tuition_assistance.view_details'),
                institution: {
                    [institutionLabel1]: institutionName1
                },
                beginDate: instance1.estimatedProgramBegin,
                beginDateVisible: instance1.tasProgramTemplate.fields?.['ESTIMATED_PROGRAM_BEGIN']?.visible,
                endDate: instance1.estimatedProgramCompletion,
                endDateVisible: instance1.tasProgramTemplate.fields?.['ESTIMATED_PROGRAM_COMPLETION']?.visible,
                result: courseApplicationResults1,
                state: instance1.state,
                isTrackingOnlyProgram: instance1.tasProgramTemplate.isTrackingOnlyProgram,
                programMajor: {
                    [majorLabel1]: instance1.programMajor
                }
            } as ProgramOverviewData;
        });
    }
    courseApplicationData(instance1: TasProgramInstanceModel): CourseApplicationResults {
        const result1 = {
            pending: 0,
            in_progress: 0,
            completed: 0
        };
        const stateInProgress1 = [
            'TAS.ApplicationState.ATTEND',
            'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL',
            'TAS.ApplicationState.EVIDENCE_APPROVED',
            'TAS.ApplicationState.EVIDENCE_NOT_APPROVED',
            'TAS.ApplicationState.EVIDENCE_REJECTED'
        ];
        return instance1.tasApplications.reduce((stateCounts1, app1)=>{
            if (app1.state === 'TAS.ApplicationState.FULFILLED') {
                stateCounts1.completed++;
            } else if (stateInProgress1.includes(app1.state)) {
                stateCounts1.in_progress++;
            } else if (app1.state !== 'TAS.ApplicationState.ABANDONED') {
                stateCounts1.pending++;
            }
            return stateCounts1;
        }, result1);
    }
    @action
    showCourseApplicationsData(program1: ProgramOverviewData) {
        return !(program1.state === 'TAS.ProgramInstanceState.DRAFT' || program1.state === 'TAS.ProgramInstanceState.PENDING_APPROVAL');
    }
    @action
    statusText(state1: string) {
        if (this.intl.exists(`status_message.to.${state1}`)) {
            return this.intl.t(`status_message.to.${state1}`);
        }
        return this.intl.t('status_message.to.TAS.ApplicationState.UPDATED_ON');
    }
    static{
        template(`
    <div ...attributes>
      <TuitionAssistanceDashboardProgramHeader />
      <div class="font-semibold mb-4">
        {{@sectionHeader}}
      </div>
      <div class="flex flex-wrap gap-4">
        {{#each this.instances as |program|}}
          <TuitionAssistanceProgramCard
            @buttonText={{program.buttonText}}
            @buttonRoute={{program.buttonRoute}}
            @buttonRouteModel={{program.buttonRouteModel}}
            @title={{program.title}}
            @titleClass="font-semibold"
            @showDashboardProgramMessagesIcon={{true}}
            @useTextLinkTo={{true}}
            @id={{program.instanceId}}
            class="mt-4 mb-2"
          >
            {{#if program.isTrial}}
              <div class="flex justify-center items-center mb-2">
                <UiPill @label={{t "common.trial"}} @bgColorClass="bg-orange-700" />
              </div>
            {{/if}}
            <div class="mt-2">
              <DefinitionList class="!p-0" as |options|>
                <div class={{options.wrappingClass}}>
                  <options.dt>{{t "common.status"}}</options.dt>
                  <options.dd class="text-violet-800">
                    {{this.statusText program.statusText}}

                  </options.dd>
                </div>
                {{#each-in program.institution as |label value|}}
                  <div class={{options.wrappingClass}}>
                    <options.dt>{{label}}</options.dt>
                    <options.dd class="font-medium">{{value}}</options.dd>
                  </div>
                {{/each-in}}
                {{#each-in program.programMajor as |label value|}}
                  {{#if (and label value)}}
                    <div class={{options.wrappingClass}}>
                      <options.dt>{{label}}</options.dt>
                      <options.dd class="font-medium">{{value}}</options.dd>
                    </div>
                  {{/if}}
                {{/each-in}}
                {{#if program.beginDateVisible}}
                  <div class={{options.wrappingClass}}>
                    <options.dt>{{t "tuition_assistance.dashboard.program_start_date"}}</options.dt>
                    {{#if program.beginDate}}
                      <options.dd class="font-medium">{{safeFormatDate
                          program.beginDate
                        }}</options.dd>
                    {{else}}
                      <options.dd class="font-medium">{{t
                          "tuition_assistance.not_provided"
                        }}</options.dd>
                    {{/if}}
                  </div>
                {{/if}}
                {{#if program.endDateVisible}}
                  <div class={{options.wrappingClass}}>
                    <options.dt>{{t "tuition_assistance.dashboard.program_end_date"}}</options.dt>
                    {{#if program.endDate}}
                      <options.dd class="font-medium">{{safeFormatDate
                          program.endDate
                        }}</options.dd>
                    {{else}}
                      <options.dd class="font-medium">{{t
                          "tuition_assistance.not_provided"
                        }}</options.dd>
                    {{/if}}
                  </div>
                {{/if}}
              </DefinitionList>
            </div>
            {{#if
              (and (this.showCourseApplicationsData program) (not program.isTrackingOnlyProgram))
            }}
              <div class="my-4">
                <p class="font-semibold">
                  {{t "tuition_assistance.dashboard.course_applications"}}
                </p>
                <DefinitionList class="!p-0" as |options|>
                  {{#each-in program.result as |key value|}}
                    <div class={{options.wrappingClass}}>
                      <options.dt>
                        {{t (concat "tuition_assistance.dashboard." key)}}
                      </options.dt>
                      <options.dd>
                        <div class="text-center border border-tio-gray-200 rounded-full w-9">
                          {{value}}
                        </div>
                      </options.dd>
                    </div>
                  {{/each-in}}
                </DefinitionList>
              </div>
            {{/if}}
          </TuitionAssistanceProgramCard>
        {{else}}
          <TuitionAssistanceProgramCard class="mt-4 mb-2">
            <p class="text-center my-2 font-semibold">
              {{t "tuition_assistance.dashboard.no_active_programs"}}
            </p>
          </TuitionAssistanceProgramCard>
        {{/each}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
