import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type StoreService from 'tio-common/services/store';
import type SessionContextService from 'tio-employee/services/session-context';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasGroupModel from 'tio-common/models/tas-group';
import type TASProgramTemplateModel from 'tio-common/models/tas-program-template';
import type TasParticipantModel from 'tio-common/models/tas-participant';

export default class AuthenticatedTasGettingStartedRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;
  @service declare tuitionAssistance: TuitionAssistanceService;

  async model({ tas_program_template_id }: { tas_program_template_id: string }) {
    // Load the TasParticipant fresh to ensure we alert any HELD participant state
    const tasParticipant = await this.store.query<TasParticipantModel>('tas-participant', {
      filter: {
        employee: this.sessionContext.currentEmployee.id,
      },
    });

    await this.tuitionAssistance.loadEligibilitiesForCurrentEmployee();

    const tasGroups = await this.store.query<TasGroupModel>('tas-group', {
      filter: {
        company: this.sessionContext.currentEmployee.company.id,
        flavor: 'TAS.GroupFlavor.MUTUALLY_EXCLUSIVE',
      },
      include: 'tas-groupings.tas-program-template',
    });

    const activeInstances = await this.store.query<TasProgramInstanceModel>(
      'tas-program-instance',
      {
        filter: { employee: this.sessionContext.currentEmployee.id, active: true },
        include: 'tas-program-template',
      }
    );

    const programTemplate = await this.store.findRecord<TASProgramTemplateModel>(
      'tas-program-template',
      tas_program_template_id
    );
    return {
      activeInstances: activeInstances,
      programTemplate: programTemplate,
      tasParticipant: tasParticipant[0],
      tasGroups: tasGroups,
    };
  }
}
