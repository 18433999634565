import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { htmlSafe } from '@ember/template';
import { not, or } from 'ember-truth-helpers';
import { scrollToHash } from 'ember-url-hash-polyfill';
import { service } from '@ember/service';
import { stateOptions } from 'tio-common/components/strategy-finder/consts';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AppContentCard from 'tio-common/components/app/content-card';
import Component from '@glimmer/component';
import config from 'tio-employee/config/environment';
import errorsForField from 'tio-common/helpers/errors-for-field';
import formatPhone from 'tio-common/helpers/format-phone';
import FormCheckbox from '@frontile/forms-legacy/components/form-checkbox';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import inputmask from 'tio-common/modifiers/inputmask';
import MaterialIcon from 'tio-common/components/material-icon';
import { pslfFormSchema } from 'tio-common/validation-schema/forms/pslf-form';
import TioAlert from 'tio-common/components/tio/alert';
import TioButton from 'tio-common/components/tio/button';
import tippy from 'ember-tippy/modifiers/tippy';
import type { IntlService } from 'ember-intl';
import type EmployeeModel from 'tio-common/models/employee';
import type PartnerService from 'tio-employee/services/partner';
import type PslfGroup from 'tio-common/models/pslf-group';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type Store from '@ember-data/store';
export interface StartNewSingleFormSignature {
    Args: {
        employee: EmployeeModel;
    };
    Element: HTMLElement;
}
export default class PslfSingleForm extends Component<StartNewSingleFormSignature> {
    @service
    router: RouterService;
    @service
    partner: PartnerService;
    @service
    store: typeof Store;
    @service
    intl: IntlService;
    @service
    sessionContext: SessionContextService;
    @tracked
    borrowerFormType = 'currentEmployer';
    @tracked
    addressLine1: string = '';
    @tracked
    addressLine2: string = '';
    @tracked
    city: string = '';
    @tracked
    employeeState: string = '';
    @tracked
    selectedState: {
        name: string;
        abbr: string;
    };
    @tracked
    postalCode: string = '';
    @tracked
    emailAddress: string = '';
    @tracked
    phoneNumber: string = '';
    @tracked
    alternatePhone: string = '';
    @tracked
    dateOfBirth: Date | null = null;
    @tracked
    dobValue: string = '';
    @tracked
    ssn: string = '';
    @tracked
    hasSubmitted: boolean | false = false;
    @tracked
    employerContactName: string = '';
    @tracked
    employerContactPhone: string = '';
    @tracked
    employerContactTitle: string = '';
    @tracked
    employerEmail: string = '';
    @tracked
    employerEin: string = '';
    @tracked
    employerName: string = '';
    @tracked
    employerWebsite: string = '';
    //@tracked employmentContinues: boolean = false;
    @tracked
    employmentEndDate: string = '';
    @tracked
    employmentHours: number = 0;
    @tracked
    employmentStartDate: string = '';
    @tracked
    employmentStatus: string = '';
    @tracked
    inviteId: string = '';
    @tracked
    isMultiEmployerFullTime: boolean = false;
    @tracked
    printAndMail: boolean = false;
    @tracked
    priorEmployerClosed: boolean = false;
    @tracked
    hasMadeQualifyingPayments: boolean = false;
    @tracked
    isForbearanceChecked: boolean = false;
    @tracked
    selectedGroup?: PslfGroup;
    @tracked
    loading: boolean = false;
    @tracked
    emailCheck: string = '';
    @tracked
    noEmployerEmail = false;
    states = stateOptions;
    constructor(owner1: unknown, args1: StartNewSingleFormSignature['Args']){
        super(owner1, args1);
        this.assignEmployeeAttributes();
    }
    assignEmployeeAttributes() {
        const employee1 = this.args.employee;
        if (employee1) {
            this.addressLine1 = employee1.addressLine1;
            this.addressLine2 = employee1.addressLine2;
            this.city = employee1.city;
            this.employeeState = employee1.state;
            this.selectedState = this.states.find((state1: {
                name: string;
                abbr: string;
            })=>state1.abbr === employee1.state)!;
            this.postalCode = employee1.postalCode;
            this.phoneNumber = employee1.phoneNumber;
            this.emailAddress = employee1.email;
        }
    }
    get isNonPartnerEmployer() {
        const employerPslfModule1 = this.partner.settingModules.pslf;
        if (!employerPslfModule1) {
            return true;
        } else {
            return employerPslfModule1.isNonPartnerEmployer;
        }
    }
    get isPriorSelected() {
        return this.borrowerFormType === 'priorEmployer';
    }
    get pslfModule() {
        return this.args.employee.company.companySetting.modules.pslf;
    }
    get pslfGroupsFromSetting() {
        return this.pslfModule?.groups || [];
    }
    get onlyOneGroup() {
        return this.pslfGroupsFromSetting.length === 1;
    }
    get defaultFein() {
        return this.args.employee.customAttributes?.fein || 0;
    }
    numbersOnly(fein1: string) {
        return fein1.replace(/[^0-9]/g, '');
    }
    get groupFromDefaultFein() {
        if (!this.defaultFein) {
            return undefined;
        } else {
            const defaultEin1 = this.numbersOnly(this.defaultFein);
            return this.pslfGroupsFromSetting.find((group1)=>this.numbersOnly(group1.employer.fein) === defaultEin1);
        }
    }
    get isPriorEmployerEnabled() {
        return this.pslfModule?.isEnabledPriorEmployer;
    }
    get isPriorOpen() {
        return this.isPriorSelected && !this.priorEmployerClosed;
    }
    get hideEmployerSection() {
        //if currentPartner=true and prior is not enabled, we don't need to ask questions about the employer
        return !this.isPriorEmployerEnabled && !this.isNonPartnerEmployer;
    }
    get showSelectEmployer() {
        return (!this.isNonPartnerEmployer && this.borrowerFormType === 'currentEmployer' && this.pslfGroupsFromSetting.length > 1 && this.groupFromDefaultFein === undefined);
    }
    setPrintAndMail() {
        if (this.priorEmployerClosed || this.noEmployerEmail) {
            return (this.printAndMail = true);
        }
    }
    @action
    setPriorEmployerClosed(value1: boolean) {
        this.priorEmployerClosed = value1;
        this.setPrintAndMail();
    }
    @action
    setNoEmployerEmail(value1: boolean) {
        this.noEmployerEmail = value1;
        this.setPrintAndMail();
    }
    @action
    setState(value1: {
        name: string;
        abbr: string;
    }) {
        this.employeeState = value1.abbr;
        this.selectedState = value1;
    }
    @action
    setDateOfBirth(value1: string) {
        this.dateOfBirth = new Date(value1);
        this.dobValue = value1;
    }
    get hasDefaultGroup() {
        return !!this.groupFromDefaultFein || this.onlyOneGroup;
    }
    get formModel() {
        const currentPartner1 = this.borrowerFormType === 'currentEmployer' && !this.isNonPartnerEmployer;
        if (currentPartner1 && this.hasDefaultGroup) {
            const defaultGroup1 = this.onlyOneGroup ? this.pslfGroupsFromSetting[0] : this.groupFromDefaultFein;
            this.setEmployerInfoFromGroup(defaultGroup1 as PslfGroup);
        }
        return {
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2,
            borrowerFormType: this.borrowerFormType,
            city: this.city,
            dateOfBirth: this.dateOfBirth,
            email: this.emailAddress,
            emailCheck: this.emailCheck,
            employerContactName: this.employerContactName,
            employerContactPhone: this.employerContactPhone,
            employerContactTitle: this.employerContactTitle,
            employerEin: this.employerEin,
            employerEmail: this.employerEmail,
            employerName: this.employerName,
            employerWebsite: this.employerWebsite,
            employmentEndDate: this.employmentEndDate,
            employmentHours: this.employmentHours,
            employmentStartDate: this.employmentStartDate,
            hasMadeQualifyingPayments: this.hasMadeQualifyingPayments,
            isForbearanceChecked: this.isForbearanceChecked,
            noEmployerEmail: this.noEmployerEmail,
            phoneNumber: this.phoneNumber,
            postalCode: this.postalCode,
            priorEmployerClosed: this.priorEmployerClosed,
            selectedGroup: this.selectedGroup,
            ssn: this.ssn,
            state: this.employeeState
        };
    }
    get formValidationErrors() {
        const schema1 = pslfFormSchema;
        const currentPartner1 = !this.isNonPartnerEmployer && this.borrowerFormType === 'currentEmployer';
        try {
            schema1?.validateSync?.(this.formModel, {
                abortEarly: false,
                context: {
                    noEmployerEmail: this.noEmployerEmail,
                    isNonPartner: this.isNonPartnerEmployer,
                    isPriorEmployer: this.isPriorSelected,
                    isPriorClosed: this.priorEmployerClosed,
                    isCurrentPartner: currentPartner1,
                    groupSelectRequired: this.showSelectEmployer
                }
            });
        } catch (err1) {
            return err1.inner || [];
        }
        return [];
    }
    @action
    setEmployerInfoFromGroup(value1: PslfGroup) {
        this.selectedGroup = value1;
        this.employerName = value1.employer.commonName || value1.employer.legalName;
        this.employerWebsite = value1.employer.website || '';
        this.employerEin = value1.employer.fein || '';
        this.employerContactName = value1.approver.name || '';
        this.employerContactPhone = value1.approver.phoneNumber || '';
        this.employerContactTitle = value1.approver.title || '';
        this.employerEmail = value1.approver.email || '';
        this.employmentStartDate = this.args.employee.employmentStartDate;
        this.employmentEndDate = this.args.employee.employmentEndDate;
    }
    @action
    submit() {
        if (this.isNonPartnerEmployer || this.isPriorSelected) {
            this.nonPartnerOrPriorSubmit();
        } else {
            this.currentPartnerSubmit();
        }
    }
    async nonPartnerOrPriorSubmit() {
        this.hasSubmitted = true;
        if (this.formValidationErrors.length) {
            scrollToHash(this.formValidationErrors[0].path);
            console.log('Form errors: ', this.formValidationErrors);
            return;
        }
        this.loading = true;
        const pslfForm1 = this.store.createRecord('pslf-form', {
            formType: this.borrowerFormType,
            payrollId: this.args.employee.payrollId,
            isNonPartner: true,
            status: 'created',
            borrowerFields: {
                'borrower-address-verification-status': 'valid',
                // TODO: Hook this up to the address verifier
                'borrower-address': this.addressLine1,
                'borrower-apartment': this.addressLine2,
                'borrower-birth-date': this.dobValue,
                'borrower-city': this.city,
                'borrower-email': this.emailAddress,
                'borrower-employer-email': this.employerEmail,
                'borrower-employer-name': this.employerName,
                'borrower-employment-continues': this.borrowerFormType === 'currentEmployer',
                'borrower-employment-start-date': this.employmentStartDate,
                'borrower-employment-end-date': this.employmentEndDate,
                'borrower-employment-hours': this.employmentHours,
                'borrower-employer-contact-name': this.employerContactName,
                'borrower-employer-contact-title': this.employerContactTitle,
                'borrower-employer-contact-phone': this.employerContactPhone ? this.enforceUSPhonePrefix(this.employerContactPhone) : '',
                'borrower-employer-website': this.employerWebsite,
                'borrower-employer-ein': this.employerEin,
                'borrower-form-type': this.borrowerFormType,
                'borrower-is-multi-employer-full-time': this.isMultiEmployerFullTime,
                'borrower-name': this.args.employee.fullName,
                'borrower-non-partner-form': this.isNonPartnerEmployer,
                'borrower-primary-phone-verified': false,
                // TODO: hook this up to phone verifier
                'borrower-primary-phone': this.enforceUSPhonePrefix(this.phoneNumber),
                'borrower-print-and-mail': this.printAndMail,
                'borrower-prior-employer-closed': this.priorEmployerClosed,
                'borrower-sign-date': new Date().toISOString(),
                'borrower-state': this.employeeState,
                'borrower-wants-forbearance': this.isForbearanceChecked,
                'borrower-zip': this.postalCode
            },
            // TODO: The BE should just add this on create.
            employerFields: {
                'employer-email': this.employerEmail,
                'employer-contact-email': this.employerEmail,
                'employment-start-date': this.employmentStartDate,
                'employment-end-date': this.employmentEndDate,
                'employment-hours': this.employmentHours
            }
        });
        pslfForm1.pslfProfile = this.args.employee.pslfProfile;
        const isSingleSigner1 = this.priorEmployerClosed || this.printAndMail;
        /* Use single signer template if prior employer is closed or if the user decides to print and mail */ const templateId1 = isSingleSigner1 ? config.signNow.singleSignerId : config.signNow.defaultId;
        // First save so the prepare method can use the id
        await pslfForm1.save();
        const signers1 = [
            {
                role: 'Borrower',
                email: this.emailAddress,
                order: 1
            }
        ];
        /* If form is not single signer, add a second signer for employer */ if (!isSingleSigner1) {
            signers1.push({
                role: 'Employer',
                email: this.employerEmail,
                order: 2
            });
        }
        await this.store.adapterFor('pslf-form').prepareForBorrowerSignature(pslfForm1, signers1, templateId1, this.ssn);
        this.router.transitionTo('authenticated.pslf.form.sign', pslfForm1.id, {
            queryParams: {
                signerType: 'borrower'
            }
        });
        this.hasSubmitted = false;
        this.loading = false;
        return;
    }
    enforceUSPhonePrefix(input1: string): string {
        // Remove any non-numeric characters from the input
        const digits1 = input1.replace(/\D/g, '');
        // Check if the cleaned number has 10 digits (add "1") or is an 11-digit starting with "1"
        if (digits1.length === 10) {
            return `+1${digits1}`;
        } else if (digits1.length === 11 && digits1.startsWith('1')) {
            return `+${digits1}`;
        }
        // If the input is not 10 digits (after cleaning), or 11 digits starting with "1", it's invalid
        throw new Error('Invalid phone number. Expected 10 digits or 11 digits starting with "1".');
    }
    async currentPartnerSubmit() {
        this.hasSubmitted = true;
        const group1 = this.selectedGroup;
        if (!group1) {
            // TODO: We should be notified if this ever happens. It would mean the data is wrong
            throw new Error('No group in PSLF module');
        }
        if (this.formValidationErrors.length) {
            // input fields need to have id that matches this path
            scrollToHash(this.formValidationErrors[0].path);
            console.log('Form errors: ', this.formValidationErrors);
            return;
        }
        this.loading = true;
        const borrowerEmployee1 = this.args.employee;
        const hours1 = borrowerEmployee1.customAttributes?.['hours-worked-per-week'];
        const isAutoSignPreApprovedForm1 = !!group1?.preApprovedTemplateId && hours1 !== undefined && hours1 >= 30;
        const pslfForm1 = this.store.createRecord('pslf-form', {
            formType: this.borrowerFormType,
            payrollId: this.args.employee.payrollId,
            isNonPartner: false,
            employerSignLink: null,
            documentId: null,
            status: 'created',
            borrowerCompletedAt: null,
            employerCompletedAt: null,
            submittedAt: null,
            borrowerFields: {
                'borrower-address-verification-status': 'valid',
                // TODO: Hook this up to the address verifier
                'borrower-address': this.addressLine1,
                'borrower-apartment': this.addressLine2,
                'borrower-birth-date': this.dobValue,
                'borrower-city': this.city,
                'borrower-email': this.emailAddress,
                'borrower-form-type': 'currentEmployer',
                'borrower-name': this.args.employee.fullName,
                'borrower-primary-phone-verified': false,
                'borrower-non-partner-form': false,
                // TODO: hook this up to phone verifier
                'borrower-primary-phone': this.enforceUSPhonePrefix(String(this.phoneNumber)),
                'borrower-print-and-mail': false,
                'borrower-prior-employer-closed': false,
                'borrower-state': this.employeeState,
                'borrower-wants-forbearance': this.isForbearanceChecked,
                'borrower-zip': this.postalCode,
                'borrower-sign-date': new Date().toISOString()
            },
            // TODO: The BE should just add this on create.
            employerFields: {
                'employer-fein': group1.employer.fein,
                'employer-name': group1.employer.legalName,
                'employer-street-address': group1.employer.address?.addressLine1,
                'employer-city': group1.employer.address?.city,
                'employer-state': group1.employer.address?.state,
                'employer-zip': group1.employer.address?.postalCode,
                'employer-website': group1.employer.website,
                'employer-group-id': group1.id,
                // rethink inclusion of this email value; 'approver' info may not always be accurate
                'employer-email': group1.approver.email,
                'employer-sign-date': isAutoSignPreApprovedForm1 ? new Date().toISOString() : '',
                'employment-continues': true,
                'employment-status': 'fullTime',
                'employment-start-date': borrowerEmployee1.employmentStartDate,
                'employment-end-date': borrowerEmployee1.employmentEndDate
            }
        });
        pslfForm1.pslfProfile = borrowerEmployee1.pslfProfile;
        await pslfForm1.save();
        const signers1 = [
            {
                role: 'Borrower',
                email: this.emailAddress,
                order: 1
            }
        ];
        if (isAutoSignPreApprovedForm1) {
            try {
                await this.store.adapterFor('pslf-form').prepareForBorrowerPreApprovedSignature(pslfForm1, signers1, group1.preApprovedTemplateId, this.ssn);
            } catch (error1) {
                console.error('Error preparing for pre-approved signature', error1);
                this.loading = false;
                return;
            }
        } else {
            signers1.push({
                role: 'Employer',
                email: this.employerEmail,
                order: 2
            });
            await this.store.adapterFor('pslf-form').prepareForBorrowerSignature(pslfForm1, signers1, config.signNow.defaultId, this.ssn);
        }
        this.router.transitionTo('authenticated.pslf.form.sign', pslfForm1.id, {
            queryParams: {
                signerType: 'borrower'
            }
        });
        this.hasSubmitted = false;
        this.loading = false;
        return;
    }
    get secondSectionHeader() {
        if (!this.hideEmployerSection) {
            return this.intl.t('pslf.workflow.about_you');
        } else {
            return '';
        }
    }
    get printAndMailAlert() {
        return this.intl.t('pslf.workflow.print_and_mail_alert');
    }
    @action
    setCurrentOrPrior(value1: string) {
        this.borrowerFormType = value1;
        if (value1 === 'priorEmployer') {
            this.employerName = '';
            this.employerWebsite = '';
            this.employerEin = '';
            this.employerContactName = '';
            this.employerContactPhone = '';
            this.employerContactTitle = '';
            this.employerEmail = '';
            this.employmentStartDate = '';
            this.employmentEndDate = '';
        }
    }
    static{
        template(`
    <form ...attributes>
      <AppContentCard
        class="!bg-white m-4"
        @titleClass="uppercase"
        @title={{t "pslf.workflow.about_your_employer"}}
      >
        <FormRadioGroup
          data-legacy-input
          @value={{this.borrowerFormType}}
          {{! @glint-expect-error: onChange wants a second param of the event as well as the value }}
          @onChange={{this.setCurrentOrPrior}}
          @hasSubmitted={{this.hasSubmitted}}
          @containerClass="p-4"
          as |Radio|
        >
          <p class="form-field-label form-input__label text-black font-semibold">{{t
              "pslf.start_new.select_employer"
            }}</p>
          {{! @glint-expect-error: children of form groups }}
          <Radio @value="currentEmployer">
            <div class="flex items-start">
              {{t "pslf.start_new.current"}}
              <MaterialIcon
                @icon="info"
                class="mx-2 text-lg leading-5 cursor-default text-ocean-600"
                {{tippy (t "pslf.start_new.current_reason") placement="right"}}
              />
            </div>
          </Radio>
          {{! @glint-expect-error: children of form groups }}
          <Radio @value="priorEmployer">
            <div class="flex items-start">
              {{t "pslf.start_new.former"}}
              <MaterialIcon
                @icon="info"
                class="mx-2 text-lg leading-5 cursor-default text-ocean-600"
                {{tippy (t "pslf.start_new.former_reason") placement="right"}}
              />
            </div>
          </Radio>
        </FormRadioGroup>
        {{#if this.showSelectEmployer}}
          {{! Select Employer }}
          <FormSelect
            data-legacy-input
            name="selectedGroup"
            @label={{t "pslf.start_new.select_employer"}}
            @isMultiple={{false}}
            @selected={{this.selectedGroup}}
            @hasSubmitted={{this.hasSubmitted}}
            @options={{this.pslfGroupsFromSetting}}
            @size="sm"
            @errors={{errorsForField "selectedGroup" schemaErrors=this.formValidationErrors}}
            @onChange={{this.setEmployerInfoFromGroup}}
            @containerClass="p-4"
            as |option|
          >
            {{! @glint-expect-error: doesn't recognize contents of 'option' }}
            {{if option.employer.commonName option.employer.commonName option.employer.legalName}}
            -
            {{! @glint-expect-error: doesn't recognize contents of 'option' }}
            {{option.employer.fein}}
          </FormSelect>
        {{/if}}
        {{! Start Date }}
        <div name="employmentStartDate">
          <FormInput
            data-legacy-input
            type="date"
            @hasSubmitted={{this.hasSubmitted}}
            @label={{t "partner.employee.employment_start_date"}}
            @containerClass="p-4"
            @value={{this.employmentStartDate}}
            @onInput={{fn (mut this.employmentStartDate)}}
            @errors={{errorsForField "employmentStartDate" schemaErrors=this.formValidationErrors}}
          />
        </div>
        {{#if this.isPriorSelected}}
          {{! End Date }}
          <div name="employmentEndDate">
            <FormInput
              data-legacy-input
              @hasSubmitted={{this.hasSubmitted}}
              type="date"
              @label={{t "partner.employee.employment_end_date"}}
              @containerClass="p-4"
              @value={{this.employmentEndDate}}
              @onInput={{fn (mut this.employmentEndDate)}}
              @errors={{errorsForField "employmentEndDate" schemaErrors=this.formValidationErrors}}
            />
          </div>
        {{/if}}
        {{! Employment Hours }}
        <div name="employmentHours">
          <FormInput
            data-legacy-input
            @label={{t "pslf.workflow.average_hours_per_week"}}
            @type="number"
            @hasSubmitted={{this.hasSubmitted}}
            @errors={{errorsForField "employmentHours" schemaErrors=this.formValidationErrors}}
            {{! @glint-expect-error: wants a string}}
            @value={{this.employmentHours}}
            {{! @glint-expect-error: wants a string}}
            @onInput={{fn (mut this.employmentHours)}}
            @containerClass="p-4"
            required={{this.priorEmployerClosed}}
          />
        </div>
        {{#if (or this.isPriorSelected this.isNonPartnerEmployer)}}
          {{#if this.isPriorSelected}}
            {{! Has Employer Closed? }}
            <FormRadioGroup
              data-legacy-input
              @containerClass="p-4"
              @hasSubmitted={{this.hasSubmitted}}
              @label={{t "pslf.workflow.refused_certify"}}
              {{! @glint-expect-error: onChange wants a second param of the event as well as the value }}
              @onChange={{fn (mut this.priorEmployerClosed)}}
              @value={{this.priorEmployerClosed}}
              as |Radio|
            >
              {{! @glint-expect-error: children of form groups }}
              <Radio @value={{true}}>
                <p class="text-black">{{t "yes"}}</p>
              </Radio>
              {{! @glint-expect-error: children of form groups }}
              <Radio @value={{false}}>
                <p class="text-black">{{t "no"}}</p>
              </Radio>
              {{#if this.priorEmployerClosed}}
                <div class="w-2/3 my-4 ml-4">
                  <TioAlert @type="info" @allowDismiss={{false}}>
                    <:body>
                      <p>{{htmlSafe this.printAndMailAlert}}</p>
                    </:body>
                  </TioAlert>
                </div>
              {{/if}}
            </FormRadioGroup>
          {{/if}}
          {{! Company Name }}
          <div name="employerName">
            <FormInput
              data-legacy-input
              @containerClass="p-4"
              @hasSubmitted={{this.hasSubmitted}}
              @errors={{errorsForField "employerName" schemaErrors=this.formValidationErrors}}
              @label="Company Name"
              @onInput={{fn (mut this.employerName)}}
              @value={{this.employerName}}
            />
          </div>

          {{#if this.priorEmployerClosed}}
            {{! EIN }}
            <div name="employerEin">
              <FormInput
                data-legacy-input
                @containerClass="p-4"
                @hasSubmitted={{this.hasSubmitted}}
                @errors={{errorsForField "employerEin" schemaErrors=this.formValidationErrors}}
                @label={{t "pslf.workflow.borrower_employer_ein_label"}}
                @onInput={{fn (mut this.employerEin)}}
                @value={{this.employerEin}}
                required={{this.priorEmployerClosed}}
              />
            </div>
            {{! Multi Employer Full Time }}
            <FormRadioGroup
              data-legacy-input
              @containerClass="p-4"
              @label={{t "pslf.workflow.multi_employer_full_time"}}
              @value={{this.isMultiEmployerFullTime}}
              {{! @glint-expect-error: onChange wants a second param of the event as well as the value }}
              @onChange={{fn (mut this.isMultiEmployerFullTime)}}
              @hasSubmitted={{this.hasSubmitted}}
              as |Radio|
            >
              {{! @glint-expect-error: children of form groups }}
              <Radio @value={{true}}>
                <p class="text-black">{{t "yes"}}</p>
              </Radio>
              {{! @glint-expect-error: children of form groups }}
              <Radio @value={{false}}>
                <p class="text-black">{{t "no"}}</p>
              </Radio>
            </FormRadioGroup>
          {{else}}
            {{! Website }}
            <div name="employerWebsite">
              <FormInput
                data-legacy-input
                @containerClass="p-4"
                @hasSubmitted={{this.hasSubmitted}}
                @errors={{errorsForField "employerWebsite" schemaErrors=this.formValidationErrors}}
                @label={{t "pslf.workflow.employer_website"}}
                @onInput={{fn (mut this.employerWebsite)}}
                @value={{this.employerWebsite}}
              />
            </div>
            {{! Employer Contact Info }}
            <FormRadioGroup
              data-legacy-input
              @containerClass="p-4"
              @label={{t "pslf.workflow_prior_employer.send_electronically"}}
              @value={{this.noEmployerEmail}}
              {{! @glint-expect-error: onChange wants a second param of the event as well as the value }}
              @onChange={{this.setNoEmployerEmail}}
              @hasSubmitted={{this.hasSubmitted}}
              as |Radio|
            >
              {{! @glint-expect-error: children of form groups }}
              <Radio @value={{true}}>
                <p class="text-black">{{t "pslf.workflow.no_employer_email"}}</p>
              </Radio>
              {{! @glint-expect-error: children of form groups }}
              <Radio @value={{false}}>
                <p class="text-black">{{t "pslf.workflow.yes_employer_email"}}</p>
              </Radio>
              {{#if this.noEmployerEmail}}
                <div class="w-2/3 my-4 ml-4">
                  <TioAlert @type="info" @allowDismiss={{false}}>
                    <:body>
                      <p>{{htmlSafe this.printAndMailAlert}}</p>
                    </:body>
                  </TioAlert>
                </div>
              {{/if}}
            </FormRadioGroup>
            {{#unless this.noEmployerEmail}}
              <div name="employerEmail">
                <p class="p-4 form-field-label form-input__label">
                  {{t "pslf.workflow.please_provide"}}
                </p>
                <FormInput
                  data-legacy-input
                  @containerClass="p-4"
                  @hasSubmitted={{this.hasSubmitted}}
                  @errors={{errorsForField "employerEmail" schemaErrors=this.formValidationErrors}}
                  @hint={{t "pslf.workflow.employer_email_hint"}}
                  @label={{t "pslf.workflow.employer_email"}}
                  @onInput={{fn (mut this.employerEmail)}}
                  @value={{this.employerEmail}}
                />
              </div>
              {{! 'Re-enter employer email' }}
              <div name="emailCheck">
                <FormInput
                  data-legacy-input
                  @containerClass="p-4"
                  @hasSubmitted={{this.hasSubmitted}}
                  @errors={{errorsForField "emailCheck" schemaErrors=this.formValidationErrors}}
                  @hint={{t "pslf.workflow.employer_email_2_hint"}}
                  @label={{t "pslf.workflow.employer_email_2"}}
                  @onInput={{fn (mut this.emailCheck)}}
                  @value={{this.emailCheck}}
                />
              </div>
            {{/unless}}
            {{! Employer Phone }}
            <div name="employerContactPhone">
              <FormInput
                data-legacy-input
                @containerClass="p-4"
                @hasSubmitted={{this.hasSubmitted}}
                @label={{t "pslf.workflow.contact_phone"}}
                @onInput={{fn (mut this.employerContactPhone)}}
                @value={{formatPhone this.employerContactPhone}}
                type="tel"
                @errors={{errorsForField
                  "employerContactPhone"
                  schemaErrors=this.formValidationErrors
                }}
              />
            </div>
            {{! Employer Contact Name }}
            <FormInput
              data-legacy-input
              @label={{t "pslf.workflow.contact_name"}}
              @hint={{t "pslf.workflow.contact_name_hint"}}
              @value={{this.employerContactName}}
              @hasSubmitted={{this.hasSubmitted}}
              @onInput={{fn (mut this.employerContactName)}}
              @containerClass="p-4"
            />
            {{! Contact’s Title (if available) }}
            <FormInput
              data-legacy-input
              @label={{t "pslf.workflow.contact_title"}}
              @hasSubmitted={{this.hasSubmitted}}
              @value={{this.employerContactTitle}}
              @onInput={{fn (mut this.employerContactTitle)}}
              @containerClass="p-4"
            />
          {{/if}}
        {{/if}}
      </AppContentCard>
      <AppContentCard
        class="!bg-white m-4"
        @titleClass="uppercase"
        @title={{this.secondSectionHeader}}
      >
        <FormRadioGroup
          data-legacy-input
          @containerClass="p-4"
          @label={{t "pslf.workflow.reason_submitting.have_you_made"}}
          {{! @glint-expect-error: onChange wants a second param of the event as well as the value }}
          @onChange={{fn (mut this.hasMadeQualifyingPayments)}}
          @value={{this.hasMadeQualifyingPayments}}
          @hasSubmitted={{this.hasSubmitted}}
          as |Radio|
        >
          {{! @glint-expect-error: children of form groups }}
          <Radio @value={{true}}>
            <p class="text-black">{{t "yes"}}</p>
          </Radio>
          {{! @glint-expect-error: children of form groups }}
          <Radio @value={{false}}>
            <p class="text-black">{{t "no"}}</p>
          </Radio>
        </FormRadioGroup>
        {{#if this.hasMadeQualifyingPayments}}
          <p class="form-field-label pl-4">
            {{t "pslf.workflow.reason_submitting.forbearance.tooltip"}}
          </p>
          <div name="forbearanceCheckbox">
            <FormCheckbox
              data-legacy-input
              {{! @glint-expect-error: label arg doesn't like SafeString}}
              @label={{htmlSafe (t "pslf.workflow.reason_submitting.forbearance.checkbox")}}
              @containerClass="px-6 py-4"
              @checked={{this.isForbearanceChecked}}
              @onChange={{fn (mut this.isForbearanceChecked) (not this.isForbearanceChecked)}}
            />
          </div>
        {{/if}}
        {{! Address }}
        <div name="addressLine1">
          <FormInput
            data-legacy-input
            @containerClass="p-4"
            @errors={{errorsForField "addressLine1" schemaErrors=this.formValidationErrors}}
            @hasSubmitted={{this.hasSubmitted}}
            @label="Address Line 1"
            @onInput={{fn (mut this.addressLine1)}}
            @size="sm"
            @value={{this.addressLine1}}
          />
        </div>

        <FormInput
          data-legacy-input
          @containerClass="p-4"
          @errors={{errorsForField "addressLine2" schemaErrors=this.formValidationErrors}}
          @hasSubmitted={{this.hasSubmitted}}
          @label="Address Line 2"
          @onInput={{fn (mut this.addressLine2)}}
          @size="sm"
          @value={{this.addressLine2}}
        />
        <div name="city">
          <FormInput
            data-legacy-input
            @containerClass="p-4"
            @errors={{errorsForField "city" schemaErrors=this.formValidationErrors}}
            @hasSubmitted={{this.hasSubmitted}}
            @label="City"
            @onInput={{fn (mut this.city)}}
            @size="sm"
            @value={{this.city}}
          />
        </div>
        <div name="state">
          <FormSelect
            data-legacy-input
            @containerClass="p-4"
            @errors={{errorsForField "state" schemaErrors=this.formValidationErrors}}
            @hasSubmitted={{this.hasSubmitted}}
            @label="State"
            @onChange={{this.setState}}
            @options={{this.states}}
            @searchField="name"
            @searchEnabled={{true}}
            @selected={{this.selectedState}}
            @size="sm"
            required="true"
            {{! @glint-expect-error: powerselect bug }}
            @renderInPlace={{true}}
            as |option|
          >
            {{! @glint-expect-error: powerselect bug }}
            {{option.name}}
          </FormSelect>
        </div>
        <div name="postalCode">
          <FormInput
            data-legacy-input
            @containerClass="p-4"
            @errors={{errorsForField "postalCode" schemaErrors=this.formValidationErrors}}
            @hasSubmitted={{this.hasSubmitted}}
            @label="Zip Code"
            @onInput={{fn (mut this.postalCode)}}
            @size="sm"
            @value={{this.postalCode}}
          />
        </div>

        {{! Contact Info }}
        <div name="phoneNumber">
          <FormInput
            data-legacy-input
            @hasSubmitted={{this.hasSubmitted}}
            @containerClass="p-4"
            @errors={{errorsForField "phoneNumber" schemaErrors=this.formValidationErrors}}
            @label="Phone Number"
            @onChange={{fn (mut this.phoneNumber)}}
            @size="sm"
            @value={{formatPhone this.phoneNumber}}
            type="tel"
          />
        </div>
        <div name="email">
          <FormInput
            data-legacy-input
            @containerClass="p-4"
            @errors={{errorsForField "email" schemaErrors=this.formValidationErrors}}
            @hasSubmitted={{this.hasSubmitted}}
            @label="Email Address"
            @onInput={{fn (mut this.emailAddress)}}
            @size="sm"
            @value={{this.emailAddress}}
          />
        </div>
        {{! Date of Birth }}
        <div name="dateOfBirth">
          <FormInput
            data-legacy-input
            @label="Date of Birth"
            type="date"
            @size="sm"
            @containerClass="p-4"
            @value={{this.dobValue}}
            @onInput={{this.setDateOfBirth}}
            @errors={{errorsForField "dateOfBirth" schemaErrors=this.formValidationErrors}}
            @hasSubmitted={{this.hasSubmitted}}
          />
        </div>
        {{! SSN }}
        <div name="ssn">
          <FormInput
            data-legacy-input
            {{inputmask alias="ssn"}}
            @containerClass="p-4"
            @errors={{errorsForField "ssn" schemaErrors=this.formValidationErrors}}
            @hasSubmitted={{this.hasSubmitted}}
            @label="SSN"
            @onInput={{fn (mut this.ssn)}}
            @size="sm"
            @value={{this.ssn}}
            autocomplete="off"
            required={{true}}
          />
        </div>
      </AppContentCard>

      <TioButton
        class="w-52 mx-auto visited:text-white my-4"
        @onClick={{this.submit}}
        @loading={{this.loading}}
      >
        {{t "common.submit"}}
      </TioButton>
    </form>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
