import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type ConditionModel from 'tio-common/models/condition';
import type RouterService from '@ember/routing/router-service';
import type StoreService from 'tio-common/services/store';

export interface UserSettings {
  security: {
    timeout: number;
    passwordMinLength: number;
  };
  register: {
    showCountdown: boolean;
    isEmailRegisterAllowed: boolean;
    isPayrollIdAndLastNameAllowed: boolean;
  };
  customTerms: ConditionModel[];
}

export interface RegisterUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  code: string;
  inviteExpired?: boolean;
  settings: UserSettings;
}

export default class EnrollRoute extends Route {
  @service declare router: RouterService;
  @service declare store: StoreService;

  queryParams = {
    inviteCode: { refreshModel: true, replace: true },
    code: { refreshModel: true, replace: true },
    email: { refreshModel: false },
  };

  async model(): Promise<RegisterUser | null> {
    const query = location.search;
    const params = new URLSearchParams(query);
    const inviteCode = params.get('inviteCode');

    try {
      const adapter = this.store.adapterFor('invite');
      return await adapter.getInviteByCode(inviteCode);
    } catch (error) {
      console.error('Error fetching invite:', error);
      return null;
    }
  }
}
