import { template } from "@ember/template-compiler";
import { action, get } from '@ember/object';
import { concat } from '@ember/helper';
import { currentNonPartnerEmployerApproval } from 'tio-common/validation-schema/forms/pslf-approver';
import { fn } from '@ember/helper';
import { trackedFunction } from 'reactiveweb/function';
import { service } from '@ember/service';
import { stateOptions } from 'tio-common/components/strategy-finder/consts';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import awsUrl from 'tio-employee/helpers/aws-url';
import Component from '@glimmer/component';
import errorsForField from 'tio-common/helpers/errors-for-field';
import FormCheckbox from '@frontile/forms-legacy/components/form-checkbox';
import FormInput from '@frontile/forms-legacy/components/form-input';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import MaterialIcon from 'tio-common/components/material-icon';
import NonPartnerSignEmbed from '../../components/pslf/non-partner-sign-embed';
import RouteTemplate from 'ember-route-template';
import type RouterService from '@ember/routing/router-service';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import type Store from '@ember-data/store';
import TioButton from 'tio-common/components/tio/button';
import tippy from 'ember-tippy/modifiers/tippy';
type NonPartnerVerifyResponse = {
    'approver-email': string;
    'borrower-form-type': string;
    'borrower-name': string;
    'borrower-sign-date': string;
    'employer-sign-date': string | undefined;
    'requires-employer-signature': boolean;
    'company-name': string;
    'company-website': string;
    'borrower-employment-start-date': string;
    'borrower-employment-end-date': string;
    'borrower-employment-continues': boolean;
    'borrower-employment-hours': string;
    'borrower-employment-status': string;
    status: string;
};
interface S {
    Args: {
        model: {
            documentId: string;
            initialValues: NonPartnerVerifyResponse;
            approvalId: string;
        };
    };
    Element: HTMLElement;
}
let PslfNonPartnerVerificationRoute = class PslfNonPartnerVerificationRoute extends Component<S> {
    @service
    store: typeof Store;
    @service
    router: RouterService;
    @tracked
    showForm: boolean = false;
    @tracked
    showEmbed: boolean = false;
    @tracked
    currentEmployee: boolean = false;
    @tracked
    employmentStartDate: string = '';
    @tracked
    employmentEndDate: string = '';
    @tracked
    weeklyHours: string = '';
    @tracked
    employmentStatus: string = '';
    @tracked
    employerWebsite: string = '';
    @tracked
    employerName: string = '';
    @tracked
    employerEin: string = '';
    @tracked
    addressLine1: string = '';
    @tracked
    addressLine2: string = '';
    @tracked
    employerState: string = '';
    @tracked
    selectedState: {
        name: string;
        abbr: string;
    };
    @tracked
    city: string = '';
    @tracked
    postalCode: string = '';
    @tracked
    hasSubmitted: boolean = false;
    @tracked
    loading = false;
    states = stateOptions;
    constructor(owner1: unknown, args1: S['Args']){
        super(owner1, args1);
        this.employerName = this.args.model.initialValues['company-name'];
        this.employerWebsite = this.args.model.initialValues['company-website'];
        this.employmentStartDate = this.args.model.initialValues['borrower-employment-start-date'];
        this.employmentEndDate = this.args.model.initialValues['borrower-employment-end-date'];
        this.weeklyHours = this.args.model.initialValues['borrower-employment-hours'];
        this.employmentStatus = this.args.model.initialValues['borrower-employment-status'];
    }
    get formInfo() {
        return this.args.model.initialValues;
    }
    get borrowerName() {
        return this.formInfo['borrower-name'];
    }
    get borrowerSignDate() {
        return this.formInfo['borrower-sign-date'];
    }
    get approverEmail() {
        return this.formInfo['approver-email'];
    }
    get approverSignatureNeeded() {
        return this.formInfo.status === 'borrower_signed';
    }
    @action
    setState(value1: {
        name: string;
        abbr: string;
    }) {
        this.employerState = value1.abbr;
        this.selectedState = value1;
    }
    @action
    toggleShowForm() {
        this.showForm = !this.showForm;
    }
    get logo() {
        return awsUrl(`tuition.png`);
    }
    get formModel() {
        return {
            approvalId: this.args.model.approvalId,
            employerApartment: this.addressLine2,
            employerCity: this.city,
            employerEin: this.employerEin,
            employerName: this.employerName,
            employerState: this.employerState,
            employerStreetAddress: this.addressLine1,
            employerWebsite: this.employerWebsite,
            employerZip: this.postalCode,
            employmentContinues: this.currentEmployee,
            employmentEndDate: this.employmentEndDate,
            employmentHours: this.weeklyHours,
            employmentStartDate: this.employmentStartDate,
            employmentStatus: this.employmentStatus,
            id: this.args.model.documentId
        };
    }
    get formValidationErrors() {
        const schema1 = currentNonPartnerEmployerApproval;
        try {
            schema1?.validateSync(this.formModel, {
                abortEarly: false,
                context: {
                    currentEmployee: this.currentEmployee
                }
            });
        } catch (e1) {
            return e1.inner || [];
        }
        return [];
    }
    embedUrlData = trackedFunction(this, async ()=>{
        const response1 = await this.store.adapterFor('pslf-form').prepareForNonPartnerEmployerSignature(this.args.model.approvalId, this.formModel);
        return response1.embedUrl;
    });
    get embedUrl() {
        return this.embedUrlData.value || '';
    }
    @action
    submit() {
        this.hasSubmitted = true;
        const errors1 = this.formValidationErrors;
        if (errors1.length) {
            console.log('Form errors: ', errors1);
            return;
        } else {
            this.loading = true;
            this.showEmbed = true;
            this.showForm = false;
            this.loading = false;
            return;
        }
    }
    static{
        template(`
    <div class="flex antialiased bg-white text-black overflow-hidden h-screen w-screen">
      <div class="flex flex-col grow h-full overflow-auto scroll-smooth">
        <div class="grid grid-cols-6 gap-4">
          {{#if this.approverSignatureNeeded}}
            {{#if this.showForm}}
              <div class="col-start-2 col-span-4 grid mt-10 mb-24">
                <form ...attributes>
                  <p class="text-lg font-semibold">
                    {{concat this.borrowerName "'s " (t "common.pslf.employment_dates")}}</p>
                  <FormCheckbox
                    @label="Still Employed"
                    @checked={{this.currentEmployee}}
                    @onChange={{fn (mut this.currentEmployee)}}
                    @containerClass="py-4"
                  />
                  <div name="employmentStartDate">
                    <FormInput
                      type="date"
                      @hasSubmitted={{this.hasSubmitted}}
                      @label={{t "pslf.workflow.start_date"}}
                      @errors={{errorsForField
                        "employmentStartDate"
                        schemaErrors=this.formValidationErrors
                      }}
                      @value={{this.employmentStartDate}}
                      @onInput={{fn (mut this.employmentStartDate)}}
                      @containerClass="py-4"
                    />
                  </div>
                  {{#unless this.currentEmployee}}
                    <div name="employmentEndDate">
                      <FormInput
                        type="date"
                        @hasSubmitted={{this.hasSubmitted}}
                        @label={{t "pslf.workflow.end_date"}}
                        @errors={{errorsForField
                          "employmentEndDate"
                          schemaErrors=this.formValidationErrors
                        }}
                        @value={{this.employmentEndDate}}
                        @onInput={{fn (mut this.employmentEndDate)}}
                      />
                    </div>
                  {{/unless}}

                  {{! WEEKLY HOURS }}
                  <div name="weeklyHours">
                    <p class="text-lg font-semibold pt-4 pb-2">
                      {{t "pslf.employer_workflow.average_hours_per_week"}}
                    </p>
                    <p>{{t "pslf.employer_workflow.hours_sub" htmlSafe=true}}</p>
                    <FormInput
                      @hasSubmitted={{this.hasSubmitted}}
                      @hint={{t "pslf.employer_workflow.average_hours_per_week"}}
                      @type="number"
                      @errors={{errorsForField
                        "weeklyHours"
                        schemaErrors=this.formValidationErrors
                      }}
                      @value={{this.weeklyHours}}
                      @onInput={{fn (mut this.weeklyHours)}}
                      @containerClass="py-4"
                    />
                  </div>
                  {{! FULL OR PART TIME }}
                  <div class="flex flex-row items-center">
                    <p class="text-lg font-semibold">{{t
                        "pslf.employer_workflow.employment_status_current"
                        name=this.borrowerName
                      }}</p>
                    <MaterialIcon
                      @icon="info"
                      class="mx-2 text-lg leading-5 align-bottom cursor-default text-ocean-600"
                      {{tippy (t "pslf.employer_workflow.full_time_means")}}
                    />
                  </div>
                  <div name="employmentStatus">
                    <FormRadioGroup
                      @hasSubmitted={{this.hasSubmitted}}
                      @value={{this.employmentStatus}}
                      {{! @glint-expect-error: onChange wants a second param of the event as well as the value }}
                      @onChange={{fn (mut this.employmentStatus)}}
                      @errors={{errorsForField
                        "employmentStatus"
                        schemaErrors=this.formValidationErrors
                      }}
                      @containerClass="py-4"
                      as |Radio|
                    >{{! @glint-expect-error: children of form groups }}
                      <Radio @value="fullTime" @label={{t "pslf.workflow.full_time"}} />
                      {{! @glint-expect-error: children of form groups }}
                      <Radio @value="partTime" @label={{t "pslf.workflow.part_time"}} />
                    </FormRadioGroup>
                  </div>
                  {{! COMPANY NAME }}
                  <div name="employerName">
                    <p class="text-lg font-semibold pt-4 pb-2">
                      {{t "pslf.approver_email_landing_page.what_is_org_name"}}</p>
                    <p class="py-2">{{t "pslf.employer_workflow.employer_name_blurb"}}</p>
                    <FormInput
                      @hint={{t "pslf.workflow.employer_name"}}
                      @hasSubmitted={{this.hasSubmitted}}
                      @value={{this.employerName}}
                      @errors={{errorsForField
                        "employerName"
                        schemaErrors=this.formValidationErrors
                      }}
                      @onInput={{fn (mut this.employerName)}}
                      @containerClass="py-4"
                    />
                  </div>
                  <div name="employerWebsite">
                    <p class="text-lg font-semibold pt-4 pb-2">
                      {{t "pslf.approver_email_landing_page.what_is_org_site"}}</p>
                    <FormInput
                      @hasSubmitted={{this.hasSubmitted}}
                      @hint={{t "pslf.workflow.employer_website"}}
                      @value={{this.employerWebsite}}
                      @errors={{errorsForField
                        "employerWebsite"
                        schemaErrors=this.formValidationErrors
                      }}
                      @onInput={{fn (mut this.employerWebsite)}}
                      @containerClass="py-4"
                    />
                  </div>
                  {{! EIN }}
                  <div name="employerEin">
                    <FormInput
                      @containerClass="py-4"
                      @hasSubmitted={{this.hasSubmitted}}
                      @errors={{errorsForField
                        "employerEin"
                        schemaErrors=this.formValidationErrors
                      }}
                      @label={{t "pslf.employer_workflow.what_is_this_orgs_ein"}}
                      @onInput={{fn (mut this.employerEin)}}
                      @value={{this.employerEin}}
                      required={{true}}
                    />
                  </div>
                  {{! Address }}
                  <div name="addressLine1">
                    <FormInput
                      @containerClass="py-4"
                      @hasSubmitted={{this.hasSubmitted}}
                      @errors={{errorsForField
                        "employerStreetAddress"
                        schemaErrors=this.formValidationErrors
                      }}
                      @label="Address Line 1"
                      @onInput={{fn (mut this.addressLine1)}}
                      @value={{this.addressLine1}}
                    />
                  </div>
                  <FormInput
                    @containerClass="py-4"
                    @hasSubmitted={{this.hasSubmitted}}
                    @errors={{errorsForField
                      "employerApartment"
                      schemaErrors=this.formValidationErrors
                    }}
                    @label="Address Line 2"
                    @onInput={{fn (mut this.addressLine2)}}
                    @value={{this.addressLine2}}
                  />
                  <div name="city">
                    <FormInput
                      @containerClass="py-4"
                      @hasSubmitted={{this.hasSubmitted}}
                      @errors={{errorsForField
                        "employerCity"
                        schemaErrors=this.formValidationErrors
                      }}
                      @label="City"
                      @onInput={{fn (mut this.city)}}
                      @value={{this.city}}
                    />
                  </div>
                  <div name="state">
                    <FormSelect
                      @containerClass="py-4"
                      @hasSubmitted={{this.hasSubmitted}}
                      @errors={{errorsForField
                        "employerState"
                        schemaErrors=this.formValidationErrors
                      }}
                      @label="State"
                      @onChange={{this.setState}}
                      @options={{this.states}}
                      @searchField="name"
                      @searchEnabled={{true}}
                      @selected={{this.selectedState}}
                      required="true"
                      as |option|
                    >
                      {{! @glint-expect-error: powerselect bug }}
                      {{option.name}}
                    </FormSelect>
                  </div>
                  <div name="postalCode">
                    <FormInput
                      @containerClass="py-4"
                      @label="Zip Code"
                      @hasSubmitted={{this.hasSubmitted}}
                      @errors={{errorsForField
                        "employerZip"
                        schemaErrors=this.formValidationErrors
                      }}
                      @onInput={{fn (mut this.postalCode)}}
                      @value={{this.postalCode}}
                    />
                  </div>
                  <TioButton
                    class="w-52 mx-auto visited:text-white my-4"
                    @onClick={{this.submit}}
                    @loading={{this.loading}}
                  >
                    {{t "common.submit"}}
                  </TioButton>
                </form>
              </div>
            {{else}}
              {{#if this.showEmbed}}
                {{#if this.embedUrl}}
                  <div class="col-start-1 col-span-6 mb-10 grid">
                    <NonPartnerSignEmbed
                      @borrowerCompletedAt={{this.borrowerSignDate}}
                      @borrowerName={{this.borrowerName}}
                      @documentId={{@model.documentId}}
                      @approvalId={{@model.approvalId}}
                      @url={{this.embedUrl}}
                    />
                  </div>
                {{/if}}
              {{else}}
                <div class="col-start-2 col-span-4 mb-10 grid">
                  <h2 class="font-semibold text-2xl my-4">{{t "pslf.default_full"}}</h2>
                  <h1 class="mb-6">{{t
                      "pslf.approver_email_landing_page.sent_to_by"
                      borrowerName=this.borrowerName
                      dateSent=(safeFormatDate this.borrowerSignDate)
                      email=this.approverEmail
                    }}
                  </h1>
                  <p class="text-xl mb-4">{{t
                      "pslf.approver_email_landing_page.explanation"
                      employerSignDate=(get @model.initialValues "employer-sign-date")
                      borrowerName=this.borrowerName
                    }}</p>
                  <p class="text-xl mb-4">{{t
                      "pslf.approver_email_landing_page.directive"
                      companyName=(get @model.initialValues "company-name")
                      borrowerName=this.borrowerName
                    }}</p>
                  <p class="text-xl mb-4">{{t
                      "pslf.approver_email_landing_page.if_in_error"
                      htmlSafe=true
                    }}</p>

                  <TioButton @onClick={{this.toggleShowForm}} class="my-8 justify-self-center">
                    {{t "pslf.approver_email_landing_page.view_form"}}
                  </TioButton>
                </div>
              {{/if}}
            {{/if}}
          {{else}}
            <div class="col-start-2 col-span-4 grid mt-10 mb-24">
              <div id="header-title" class="flex flex-col header-left grow mb-10" ...attributes>
                <img
                  src={{this.logo}}
                  class="h-10 max-w-32 object-contain"
                  alt={{t "tuition_io_lowercase"}}
                  title={{t "tuition_io_lowercase"}}
                />
                <div class="text-2xs text-gray-600 mt-1">
                  {{t
                    "header.banner.tagline"
                    programTitle="education assistance benefits"
                    brandClass="text-purple-900 font-semibold"
                    htmlSafe=true
                  }}
                </div>
              </div>
              <p class="text-xl m-10">{{t "pslf.form_no_longer_awaiting_approval"}}</p>
            </div>
          {{/if}}
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(PslfNonPartnerVerificationRoute);
